import { OffersFacade } from '@/_store/offers/offers.facade';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NaviService, Position } from '../navi.service';

@Component({
	selector: 'app-navi-small',
	templateUrl: './navi-small.component.html',
	styleUrls: ['./navi-small.component.css'],
	animations: [
		trigger('sidebarTrigger', [
			state('open', style({ transform: 'translateX(0%)' })),
			state('close', style({ transform: 'translateX(-100%)' })),
			transition('open => close', [animate('300ms ease-in')]),
			transition('close => open', [animate('300ms ease-out')]),
		]),
	],
})
export class NaviSmallComponent implements OnInit, OnDestroy {
	menuPositions: Position[] = [];
	showSidebar = false;
	expandedMenu: number[] = [];
	searchbarVisible = false;
	href = 'tel: 61 852 45 72';
	readonly homepageRoute = '/';

	private menuServiceSubscription: Subscription = new Subscription();

	constructor(
		private readonly menuService: NaviService,
		private readonly router: Router,
		private readonly offersFacade: OffersFacade,
	) {}

	ngOnInit(): void {
		this.menuServiceSubscription = this.menuService.list.subscribe((menu: Position[]) => {
			this.menuPositions = menu;
		});
	}

	showSubMenu(id: number): void {
		const index = this.expandedMenu.indexOf(id);
		if (index === -1) {
			this.expandedMenu.push(id);
		} else {
			this.expandedMenu.splice(index, 1);
		}
	}

	ngOnDestroy(): void {
		this.menuServiceSubscription.unsubscribe();
	}

	toggleSearchbar(): void {
		this.searchbarVisible = !this.searchbarVisible;
	}

	search(value: string): void {
		this.menuService.search(value);
		this.searchbarVisible = !this.searchbarVisible;
	}

	routerSideActions(event: MouseEvent): void {
		this.showSidebar = false;
		const isCtrlPressed = event.ctrlKey || event.metaKey;
		const urlWithoutParams = this.router.url.split('?')[0];

		if (!isCtrlPressed && urlWithoutParams !== this.homepageRoute) {
			this.offersFacade.setInitialState();
		}
	}

	moveToSubItem(subitem: Position, groupName: string): void {
		this.menuService.moveToSubItem(subitem, groupName);
	}
}
