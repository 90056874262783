import { APP_ID, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// Material
import { MaterialModule } from './modules/shared/material/material.module';
// Swiper
import { SwiperModule } from 'swiper/angular';
import { SwiperComponent } from 'swiper/angular';

// CDK
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

// App
import { AppComponent } from './app.component';
import { OfferBoxComponent } from './common/offer-box/offer-box.component';
import { FooterComponent } from './common/footer/footer.component';
import { NaviComponent } from './navi/navi.component';
import { NaviSmallComponent } from './navi/navi-small/navi-small.component';
import { NaviBigComponent } from './navi/navi-big/navi-big.component';
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';
import { MainComponent } from './main/main.component';
import { MainHeaderComponent } from './main/main-header/main-header.component';
import { MainStandardComponent } from './main/main-standard/main-standard.component';
import { MainHighlightedComponent } from './main/main-highlighted/main-highlighted.component';
import { MainCompactComponent } from './main/main-compact/main-compact.component';
import { MainCommonComponent } from './main/main-common/main-common.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { OfferBoxMobileComponent } from './common/offer-box-mobile/offer-box-mobile.component';
import { OfferBoxMobileHighlightComponent } from './common/offer-box-mobile-highlight/offer-box-mobile-highlight.component';
import { OfferBoxMobileHorizontalComponent } from './common/offer-box-mobile-horizontal/offer-box-mobile-horizontal.component';
import { MainHorizontalComponent } from './main/main-horizontal/main-horizontal.component';
import { DestinationBoxComponent } from './common/destination-box/destination-box.component';
import { AvailableBoardsDialogComponent } from './common/offer-box/available-boards-dialog/available-boards-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { ButtonModule } from './modules/shared/button/button.module';
import { PipesModule } from './modules/shared/pipes/pipes.module';
import { ContactFormComponent } from './common/contact-form/contact-form.component';
import { CustomSelectComponent } from './common/custom-select/custom-select.component';
import { SuccessFooterDialogComponent } from './common/footer/success-footer-dialog/success-footer-dialog.component';
import { OfferGuard } from './offer/offer.guard';
import { MainGuard } from './main/main.guard';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { OfferUrlBuilderService } from './common/offer-url-builder.service';
import { MobileWrapperModule } from './modules/shared/mobile-wrapper/mobile-wrapper.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { currentOfferReducer } from './_store/current-offer/current-offer.reducer';
import { CurrentOfferEffects } from './_store/current-offer/current-offer.effects';
import { HTTP_CLIENT_SSR_TIMEOUT, HttpTimeoutInterceptor } from './interceptors/timeout.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { generalReducer } from './_store/general/general.reducer';
import { NewTopSearchModule } from '@/new-top-search/new-top-search.module';
import { offersReducer } from '@/_store/offers/offers.reducer';
import { OffersEffects } from '@/_store/offers/offers.effects';
import { SharedModule } from './shared.module';
import { metaReducers } from './_store/meta-reducer';
import { getInitialAppState } from './_store/get-initial-app-state';
import { API_URL_TOKEN } from '@oskar-frontend/comfort-package';

import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl);

const routes: Routes = [
	{
		path: '',
		component: MainComponent,
		pathMatch: 'full',
		canActivate: [MainGuard],
	},
	{
		path: 'wakacje-autokarem/:slug/:slug',
		loadChildren: () => import('./offer/new-offer-list/offer-list.module').then((m) => m.OfferListModule),
	},
	{
		path: 'wakacje-autokarem',
		loadChildren: () => import('./offer/new-offer-list/offer-list.module').then((m) => m.OfferListModule),
	},
	{
		path: 'rezerwacja',
		loadChildren: () => import('./reservation-form/reservation-form.module').then((m) => m.ReservationFormModule),
	},
	{
		path: 'rezerwacja/podsumowanie/:hash',
		loadChildren: () =>
			import('./modules/reservation-form-summary/reservation-form-summary.module').then(
				(m) => m.ReservationFormSummaryModule,
			),
	},
	{
		path: 'oferta/:id',
		loadChildren: () => import('./offer/offer.module').then((m) => m.OfferModule),
		canActivate: [OfferGuard],
	},
	{
		path: 'login',
		loadComponent: () => import('./auth/login/login.component').then((mod) => mod.LoginComponent),
	},
	{
		path: '**',
		pathMatch: 'full',
		component: NotFoundComponent,
	},
];

@NgModule({
	declarations: [
		AppComponent,
		OfferBoxComponent,
		NaviComponent,
		NaviSmallComponent,
		NaviBigComponent,
		BannerCarouselComponent,
		MainComponent,
		MainHeaderComponent,
		MainStandardComponent,
		MainHighlightedComponent,
		MainCompactComponent,
		MainCommonComponent,
		NotFoundComponent,
		LoaderComponent,
		OfferBoxMobileComponent,
		OfferBoxMobileComponent,
		OfferBoxMobileHighlightComponent,
		OfferBoxMobileHorizontalComponent,
		MainHorizontalComponent,
		DestinationBoxComponent,
		AvailableBoardsDialogComponent,
		ContactFormComponent,
		CustomSelectComponent,
		SuccessFooterDialogComponent,
	],
	bootstrap: [AppComponent],
	exports: [RouterModule],
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking',
		}),
		BrowserAnimationsModule,
		PipesModule,
		ButtonModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		LayoutModule,
		FormsModule,
		ReactiveFormsModule,
		DragDropModule,
		SwiperModule,
		MatDialogModule,
		NgOptimizedImage,
		NewTopSearchModule,
		MaterialModule,
		MobileWrapperModule,
		SharedModule,
		StoreModule.forRoot(
			{ currentOffer: currentOfferReducer, general: generalReducer, offers: offersReducer },
			{ metaReducers, initialState: getInitialAppState },
		),
		EffectsModule.forRoot([CurrentOfferEffects, OffersEffects]),
		StoreDevtoolsModule.instrument({
			maxAge: 50,
			logOnly: environment.production,
		}),
		FooterComponent,
	],
	providers: [
		SwUpdate,
		DatePipe,
		HttpClient,
		SwiperComponent,
		LoaderService,
		{
			provide: HTTP_CLIENT_SSR_TIMEOUT,
			useValue: 5000,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpTimeoutInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{
			provide: API_URL_TOKEN,
			useValue: environment.apiUrl,
		},
		MainGuard,
		OfferUrlBuilderService,
		{ provide: APP_ID, useValue: 'serverApp' },
		{ provide: LOCALE_ID, useValue: 'pl-PL' },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
