<div class="px-2 h-[auto] mb-10">
	<app-main-header [link]="link"
		><h1 class="text-lg md:text-3xl mb-[10px]">
			<span class="text-accentColor"> {{ title }}</span
			><span class="text-mainColor"> {{ titleSuffix }}</span>
		</h1>
	</app-main-header>
	<div *ngIf="!dataLoaded" class="flex flex-col justify-center items-center p-24">
		<h1 class="text-mainColor font-bold text-2xl">Wczytujemy dane, prosimy o cierpliwość :)</h1>
		<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
	</div>
	<div *ngIf="dataLoaded" class="flex flex-row items-center">
		<swiper [config]="swiperConfig">
			<ng-template swiperSlide *ngFor="let item of data; let i = index">
				<app-offer-box-mobile-highlight
					class="flex items-center justify-center"
					[dataToTransfer]="dataToTransfer[i]"
					[data]="item"
				></app-offer-box-mobile-highlight>
			</ng-template>
		</swiper>
	</div>
</div>
