<div class="bg-mainColor text-white flex flex-col justify-center py-5">
	<form [formGroup]="newsletterFormGroup" class="lg:max-w-[1440px] w-full mx-auto">
		<h3 class="text-2xl text-center md:text-left font-semibold">Zapisz się do Newslettera i zgarnij 5% zniżki!</h3>
		<div class="flex flex-col items-center md:flex-row my-3">
			<div class="text-center w-fit mb-5 md:mb-3 relative">
				<input
					type="email"
					formControlName="email"
					placeholder="Wpisz adres e-mail"
					class="rounded-2xl bg-white text-mainColor border-1 border-white focus:outline-none px-5 py-3 w-[320px] md:mr-8"
					[ngClass]="{
						'!border-[#CF6679]':
							newsletterFormGroup.get('email')?.invalid && newsletterFormGroup.get('email')?.touched,
					}"
				/>
				<small
					[ngClass]="{
						'error-label-visible':
							newsletterFormGroup.get('email')?.invalid && newsletterFormGroup.get('email')?.touched,
					}"
					class="text-[#CF6679] font-extrabold absolute left-0 bottom-[-10px] transition opacity-0 cursor-default duration-150"
					>{{
						newsletterFormGroup.get('email')?.hasError('required')
							? 'Adres email jest wymagany'
							: 'Niepoprawny adres email'
					}}
				</small>
				@if (loading()) {
					<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
				}
			</div>
			@if (!loading()) {
				<app-button (click)="signUpToNewsletter()" class="self-center md:self-start" type="footer">
					Zapisz się
				</app-button>
			}
		</div>

		@if (!!response()) {
			<p class="text-[#fff] mb-5 md:mb-3 font-extrabold transition cursor-default duration-150">
				<b>{{ response() }}</b>
			</p>
		}

		<div class="flex md:mx-0 mx-6">
			<div class="relative">
				<input
					type="checkbox"
					name="consent"
					id="consent"
					formControlName="consent"
					class="w-8 h-5 bg-white relative accent-accentColor text-white mr-4 cursor-pointer z-20"
				/>
				<div
					[ngClass]="{
						'!opacity-100':
							(showError$ | async) &&
							newsletterFormGroup.get('consent')?.invalid &&
							newsletterFormGroup.get('email')?.touched,
					}"
					class="border-1 border-red md:top-full md:left-[-26px] left-[80%] top-[-7px] z-30 bg-white text-red text-xs absolute w-max px-4 py-2 rounded-lg cursor-default transition-all opacity-0"
				>
					<i class="fas fa-exclamation-circle mr-1"></i>
					Wymagana zgoda
				</div>
			</div>
			<label for="consent" class="text-xs text-justify cursor-default">
				Wyrażam zgodę na przetwarzanie danych osobowych przez Biuro Podróży Oskar Sp. z o.o. w celach
				marketingowych, w zakresie oraz celu wskazanym w „Informacji o przetwarzaniu danych osobowych”, poprzez
				elektroniczną formę komunikacji (e-mail), także z użyciem tzw. automatycznych systemów wywołujących.
			</label>
		</div>
	</form>
</div>
