import { Component, Input, ElementRef, ViewChild, OnInit, Inject } from '@angular/core';
import { MainCommonComponent } from '../main-common/main-common.component';
import { OfferBox } from '@/common/offer-box/offer-box.model';
import { AvailableBoardsDialogComponent } from '@/common/offer-box/available-boards-dialog/available-boards-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { DateService } from '@/common/date.service';
import { DOCUMENT } from '@angular/common';
import { AuthBagService } from '@/auth/auth-bag.service';
import { MainService } from '../main.service';
import { Data } from '@/offer/offer.model';
import { TransferState } from '@angular/core';
import { removeLastChar } from '@/_utils/strings/remove-last-char';
import { ParamsSplit } from '@/common/paramsSplit.service';
import { DataService, Result } from '@/common/data.service';

@Component({
	selector: 'app-main-compact',
	templateUrl: './main-compact.component.html',
	styleUrls: ['./main-compact.component.css'],
})
export class MainCompactComponent extends MainCommonComponent implements OnInit {
	@Input() titleSuffix: string | undefined;
	@ViewChild('boardsTooltip') boardsTooltip!: ElementRef<HTMLElement>;
	dataLoaded = false;
	fav = false;
	protected dataToTransfer: Data[] = [];

	constructor(
		bag: DataService,
		paramsSplit: ParamsSplit,
		router: Router,
		auth: AuthBagService,
		service: MainService,
		dateService: DateService,
		private readonly dialog: MatDialog,
		@Inject(DOCUMENT) protected document: Document,
		private readonly transferState: TransferState,
	) {
		super(bag, paramsSplit, router, auth, service, dateService);
	}

	override ngOnInit(): void {
		this.dataLoaded = false;
		this.bag.get(
			`www/offer/?${this.paramsSplit.splitParams(this.query)}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
			(result: Result<Data>) => {
				if (result) {
					this.dataToTransfer = result.results;
					this.data = this.service
						.getOffers(result.results)
						// eslint-disable-next-line  @typescript-eslint/no-explicit-any
						.map((offer: any, i: number) => {
							if (offer)
								return {
									promotionImage: offer.promotion?.catalog.multimedia,
									productId: offer.room.term.product.id,
									image: offer.room.term.product.multimedias[0].multimedia.url,
									label: 'First minute',
									title: offer.room.term.product.name,
									location: offer.room.term.product.locations[0].name,
									date: this.dateService.convertDateRange(
										`${offer.stop.departureDatetime} - ${offer.stop.returnDatetime}`,
									),
									food: offer.board.name,
									nights:
										offer.room?.term.nightsStay == 0
											? `Bez noclegu`
											: `${offer.room?.term.nightsStay} ${this.getNightsString(
													offer.room?.term.nightsStay,
												)}`,
									transport: 'Autokarem',
									discounts: ['-20%', '-100 PLN'],
									price: offer.fullPrice,
									priceCatalog: offer.catalogPrice,
									params: {
										stop: offer.stop.stop,
										roomType: offer.room.roomType,
										adults: offer.room.adults,
										children: offer.room.children,
										stopAddres: offer.stop.address,
										dateRange: `${offer.room.term.dateRange.lower},${offer.room.term.dateRange.upper}`,
										boards: offer.board.board,
										promotion: offer.promotion?.catalog.id ? offer.promotion.catalog.id : '',
									},
									href:
										'../oferta/' +
										offer.room.term.product.i +
										'?' +
										this.getUrlParams(this.dataToTransfer[i]),
								};
							return <OfferBox>{};
						})
						.slice(0, 10);
					this.dataLoaded = true;
				}
			},
		);
	}

	locationShort(name: string): string {
		return name.length > 25 ? name.substring(0, 25).concat('...') : name;
	}

	getUrlParams(item: Data, target: 'url' | 'object' = 'url'): string | Params {
		let roomType;
		const daterange = `${item.room.term.dateRange.lower},${item.room.term.dateRange.upper}`;
		if (!item.room.roomType) {
			roomType = '';
		} else {
			roomType = item.room.roomType.id;
		}
		const adults = item.room.adults;
		const children = item.room.children;
		const ownTransport = !item.stop.address;
		const promotion = item.promotion ? item.promotion.catalog.id : 0;
		const params = {
			boards: item.board.board,
			promotion_catalogs: promotion,
			room_type: roomType,
			stops: item.stop.stop,
			date_range: daterange,
			own_transport: ownTransport,
			adults: adults,
			children: children,
			products: item.room.term.product.id,
			nights: item.room.term.nightsStay,
		};

		let url = '';
		for (const [key, value] of Object.entries(params)) {
			url = url.concat(`${key}=${value}&`);
		}

		url = removeLastChar(url);

		return target === 'url' ? url : params;
	}

	getNightsString(nights: number): string {
		if (nights === 1) return 'noc';
		else if (nights > 1 && nights < 5) return 'noce';
		return 'nocy';
	}

	displayMoreFoodInfo(event: Event, item: OfferBox): void {
		event.preventDefault();
		event.stopPropagation();

		const iconPosition = this.boardsTooltip.nativeElement.getBoundingClientRect();

		this.dialog.open(AvailableBoardsDialogComponent, {
			hasBackdrop: true,
			position: {
				top: `${iconPosition.y + 20}px`,
				left: `${iconPosition.left - 115}px`,
			},
			data: {
				productId: item.productId,
			},
		});
	}

	handleButtonClick(event: Event, index: number): void {
		event.preventDefault();
		event.stopPropagation();

		this.goToOffer(index);
	}

	private goToOffer(index: number): void {
		const params: Params = this.getUrlParams(this.dataToTransfer[index], 'object') as Params;

		this.router.navigate([`../oferta/${this.data[index].productId}`], {
			queryParams: Object.assign({}, params),
		});
	}
}
